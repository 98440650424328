import constant from "../constant";

export const ErrorModule = {
    namespaced: true,

    state: {
        error: {
            error_code: null,
            type: null,
            message: null,
            field: null,
            display_modal: false, //display error as modal
            full_page: false,    // display error as full page
        },
    },

    // Mutations are functions that effect the state. Never called by developer, it is called by actions function
    mutations: {
        SET_ERROR(state, error) {
            state.error.type = error.type;
            state.error.message = error.message;
            state.error.error_code = error.error_code
            state.error.display_modal = true;
        },
        CLEAR_ERROR(state) {
            state.error.type = null;
            state.error.message = null;
            state.error.error_code = null;
            state.error.display_modal = false;
        },
        SET_ERROR_MESSAGE(state, error) {
            state.error.type = error.type;
            state.error.error_code = error.error_code
            state.error.message = error.message;
            state.error.field = error.field;
            state.error.display_modal = false;
        },
        OPEN_MODAL(state, error) {
            state.error.display_modal = true;
        },
        DISPLAY_FULL_PAGE(state) {
            state.error.full_page = true;
        }
    },


    // Actions are functions that you call through your application that call mutations.
    actions: {
        /**
         * Set Error message only. This doesn't display glabal error popup
         * Use this for Error page
         * @param commit
         * @param error
         */
        setMessage({commit}, {error: error}) {
            commit('SET_ERROR_MESSAGE', error);
        },

        /**
         * Set Error message only from API. This doesn't display glabal error popup
         * Use this for Error page
         * @param commit
         * @param error
         */
        setMessageFromAPI({rootState, commit}, error) {

            let error_message = '';
            let error_code = error.response.data.error_code;
            // Message priority is from api
            if ([constant.ERROR_TEST_UNAVAILABLE, constant.ERROR_INVALID_RESUME_LINK].includes(error_code)) {
                error_message = error.response.data.error_message ?? rootState.Language.data[error_code];
            } else if ([constant.ERROR_GENERIC_SERVER_ERROR].includes(error_code)) {// generic server error
                let temp_message = error.response.data.error_message;
                if (temp_message.includes('PayPal')) {
                    error_message = temp_message;
                } else {
                    error_message = rootState.Language.data[error_code]
                }
            } else {
                // Message priority is from lanugage set
                error_message = rootState.Language.data[error_code] ?? error.response.data.error_message;
            }

            let field = error.response.data.field ?? null
            let payload = {
                type: error.response.data.error_level,
                error_code: error_code,
                message: error_message,
                field: field
            }
            commit('SET_ERROR_MESSAGE', payload);
        },
        /**
         * Setup error message and type and display Global Error Modal
         * @param commit
         * @param error =>{type: String, message: String}
         */
        display({commit}, error) {
            commit('SET_ERROR', error);
        }
        ,

        /**
         * Setup error message from ClassMarker API Error response and display Global Error Modal
         * @param commit
         * @param error
         */
        displayFromAPIError({commit, rootState}, error) {

            let error_code = error.response.data.error_code;
            let error_message = rootState.Language.data[error_code] ?? error.response.data.error_message;
            let payload = {
                type: error.response.data.error_level,
                error_code: error_code,
                message: error_message
            }
            commit('SET_ERROR', payload);
        }
        ,
        /**
         * Close Error modal
         * @param commit
         */
        close({commit}) {
            commit('CLEAR_ERROR');
        }
        ,

        openModal({commit}) {
            commit('OPEN_MODAL');
        }
        ,
        displayFullPageError({commit}) {
            commit('DISPLAY_FULL_PAGE');
        }
    },

// Modulising stores - spreading stores into different files
    modules: {}
}