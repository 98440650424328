<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true" scrollX="true" scrollY="true">
      <Header></Header>
      <div class="template">
        <TestTitle :display-price="false"></TestTitle>
        <form @submit="updateUser($event)">
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col size="12">
                <ion-list class="extra__form ion-no-padding" lines="none">


                  <ion-item v-if="state.settings.first_name_required && $namesInReverseOrder(state.language_set['code']) == false">
                    <div class="input">
                      <ion-label class="ion-text-wrap label default-font required" for="first_name"
                                 :class="{'error':state.errors.first_name}">{{
                          state.language_set.ex_form_first_name
                        }}
                      </ion-label>
                      <ion-input class="small" :placeholder="state.language_set.ex_form_first_name" type="text" id="first_name" max-length="50"
                                 name="first_name" v-model="state.user.first_name"
                                 :class="{'error':state.errors.first_name}"></ion-input>
                      <div class="form_error">{{ state.errors.first_name }}</div>
                    </div>

                  </ion-item>

                  <ion-item v-if="state.settings.last_name_required">
                    <div class="input">
                      <ion-label for="last_name" class="ion-text-wrap label default-font required"
                                 :class="{'error':state.errors.last_name}">
                        {{ state.language_set.ex_form_last_name }}
                      </ion-label>
                      <ion-input :placeholder="state.language_set.ex_form_last_name" class="small" type="text"
                                 id="last_name"
                                 max-length="50"
                                 :class="{'error':state.errors.last_name}"
                                 name="last_name" v-model="state.user.last_name">
                      </ion-input>
                      <div class="form_error">{{ state.errors.last_name }}</div>
                    </div>

                  </ion-item>

                  <ion-item v-if="state.settings.first_name_required && $namesInReverseOrder(state.language_set['code'])">
                    <div class="input">
                      <ion-label class="ion-text-wrap label default-font required" for="first_name"
                                 :class="{'error':state.errors.first_name}">{{
                          state.language_set.ex_form_first_name
                        }}
                      </ion-label>
                      <ion-input class="small" :placeholder="state.language_set.ex_form_first_name" type="text" id="first_name" max-length="50"
                                 name="first_name" v-model="state.user.first_name"
                                 :class="{'error':state.errors.first_name}"></ion-input>
                      <div class="form_error">{{ state.errors.first_name }}</div>
                    </div>

                  </ion-item>


                  <ion-item v-if="state.settings.email_required">
                    <div class="input">
                      <ion-label for="email" class="ion-text-wrap label default-font required"
                                 :class="{'error':state.errors.email}">
                        {{
                          state.language_set.ex_form_email
                        }}
                      </ion-label>
                      <ion-input type="email" :placeholder="state.language_set.ex_form_email" class="width-90"
                                 id="email" name="email" mode="md"
                                 :class="{'error':state.errors.email}"
                                 v-model="state.user.email"></ion-input>
                      <div class="form_error">{{ state.errors.email }}</div>
                    </div>

                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>

            <ion-row v-if="state.renderExtraInfo" class="extra-question-area">
              <ion-col size="12">
                <ion-list class="extra__form ion-no-padding" lines="none" >
                  <div v-for="(extra_info_question, index) in  state.extra_info_questions" :key="index">

                    <ion-item v-if="extra_info_question.length !== 0" class="extra-question-item"
                              :data-cy="'user-info-'+index">

                      <ExtraQuestionCheckBox
                          v-if="extra_info_question.answer_type == 'checkbox'"
                          :id="index"
                          :extra-info-question="extra_info_question"
                          :must-answer="must_answer_extra_question"
                          :error="state.errors.extra_question_answers[index]"
                          v-on:answer_change="updateUserOptionalAnswers"
                      />

                      <ExtraQuestionInputBox
                          v-if="extra_info_question.answer_type == 'input_box'"
                          :id="index"
                          :extra-info-question="extra_info_question"
                          :must-answer="must_answer_extra_question"
                          :error="state.errors.extra_question_answers[index]"
                          v-on:answer_change="updateUserOptionalAnswers"
                      />

                      <ExtraQuestionTextBox
                          v-if="extra_info_question.answer_type == 'text_box'"
                          :id="index"
                          :extra-info-question="extra_info_question"
                          :must-answer="must_answer_extra_question"
                          v-on:answer_change="updateUserOptionalAnswers"
                          :error="state.errors.extra_question_answers[index]"
                      />

                      <ExtraQuestionDropDown
                          v-if="extra_info_question.answer_type == 'drop_down'"
                          :id="index"
                          :extra-info-question="extra_info_question"
                          :must-answer="must_answer_extra_question"
                          v-on:answer_change="updateUserOptionalAnswers"
                          :error="state.errors.extra_question_answers[index]"
                      />
                    </ion-item>
                  </div>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>


          <div class="button-area">
            <ion-button class="next-button" type="submit" :disabled="state.button_disabled" data-cy="continue-btn">
              {{ state.language_set.ex_link_start_test }}
            </ion-button>
          </div>

        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow
} from '@ionic/vue';
import {defineComponent, reactive, watch} from 'vue';
import store from "@/store";
import ExtraQuestionInputBox from "../components/user/ExtraQuestionInputBox";
import ExtraQuestionTextBox from "../components/user/ExtraQuestionTextBox";
import ExtraQuestionDropDown from "../components/user/ExtraQuestionDropDown";
import ExtraQuestionCheckBox from "../components/user/ExtraQuestionCheckBox"
import RouteHandler from "../router/routeHandler";
import {useRouter} from "vue-router";
import Header from "../components/html/Header";
import validateUtil from "../utils/validateUtil";
import TestTitle from "../components/html/TestTitle";
import constant from "../constant";

export default defineComponent({
  name: 'UserInfo',
  components: {
    TestTitle,
    ExtraQuestionDropDown,
    ExtraQuestionTextBox,
    ExtraQuestionInputBox,
    ExtraQuestionCheckBox,
    IonContent,
    Header,
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    IonList,
    IonButton,
    IonLabel,
    IonInput,
    IonItem,

  },

  setup(props, ctx) {
    const state = reactive({
      user: { // User Object
        first_name: null,
        last_name: null,
        email: null,
        extra_question_answers: []
      },
      settings: store.state.Test.settings['login'], //  test settings
      extra_info_questions: store.state.User.user['extra_info_questions'],  // demographic question lists
      language_set: store.getters.languageSet, // language set for display
      errors: { // error s object
        first_name: null,
        last_name: null,
        email: null,
        extra_question_answers: Array(5),
      },
      button_disabled: false,
      renderExtraInfo: false,
    });





    const route_handler = new RouteHandler(useRouter());
    const {validateEmail, validateName} = new validateUtil();
    const must_answer_extra_question = state.settings.user_extra_info_mandatory;
    setupUser();
    setUpUserAnswer();

    function setupUser() {
      if (state.settings.first_name_required) {
        state.user.first_name = store.state.User.user['first_name'] ?? null;
      }
      if (state.settings.last_name_required) {
        state.user.last_name = store.state.User.user['last_name'] ?? null;
      }
      if (state.settings.email_required) {
        state.user.email = store.state.User.user['email'] ?? store.state.User.user['login_email'];
      }
    }

    function setUpUserAnswer() {
      let extra_info = JSON.parse(JSON.stringify(store.state.User.user['extra_info_questions']))

      if (state.settings.user_extra_info_required && extra_info.length > 0) {
        for (let i = 0; i < extra_info.length; i++) {
          if ('question' in extra_info[i] && extra_info[i]['question']) {
            state.user.extra_question_answers[i] = extra_info[i]['user_answer'];
            state.renderExtraInfo = true;
          }
        }
      }
    }


    /**
     * Update User's Answer to the Server.
     * @param event
     */
    function updateUser(event) {
      event.preventDefault();
      if (!__validateForm()) {
        let h1_header = document.querySelector('#test-title');
        let scroll_position = h1_header.offsetHeight; //-52 for adjust to show entire card
        let content = document.querySelector('ion-content');
        content.scrollToPoint(0, scroll_position, 0);
        return;
      }

      state.button_disabled = true;
      store.dispatch('User/update', {user: state.user}).then(() => {
        state.button_disabled = false;
        route_handler.route();
      }).catch((error) => {

        if (store.state.Error.error.error_code == constant.ERROR_NO_INTERNET) {
          store.dispatch('Error/openModal');
        } else {
          let data = error.response.data;
          let error_code = data.error_code;
          if (error_code == 'ex_error_details') { // Demographic Questions area not answered
            __validateDemographicQuestion();

          } else if (error_code == 'name_invalid') {
            let error_field = data.field;
            state.errors[error_field] = state.language_set[error_code];
          }
        }
        state.button_disabled = false;
      });
    }

    /**
     * Detect User's asnwer change from child components and update state
     * @param object
     */
    function updateUserOptionalAnswers(object) {
      state.user.extra_question_answers[object['question_id']] = object['value'];
    }

    /**
     * Validate User answers for Demographics questions
     * @returns {boolean}
     * @private
     */
    function __validateDemographicQuestion() {

      let valid = true;

      for (let i = 0; i < 5; i++) {
        let answer = state.user.extra_question_answers[i];
        if (state.extra_info_questions[i].question) {
          // case 1:  string
          if (!answer || typeof answer === 'string' && answer.trim() == '') {
            state.errors.extra_question_answers[i] = state.language_set['ex_error_details'];
            valid = false;
            // case 2: object
          } else if (Array.isArray(answer)) {
            answer = JSON.parse(JSON.stringify(answer));
            if (answer.length == 0) {
              state.errors.extra_question_answers[i] = state.language_set['ex_error_details'];
              valid = false;
            }
          }
        }
      }
      return valid;
    }

    /**
     * Clear Error messages
     * Detect input model update and clear error message when there is an update.
     **/
    // detect error modal closes and scroll to the specific question if required.

    watch(() => state.user, (newValue, oldValue) => {

      //First name
      if (newValue.first_name != null && newValue.first_name != '') {
        state.errors.first_name = null;
      }

      //Last name
      if (newValue.last_name != null && newValue.last_name != '') {
        state.errors.last_name = null;
      }

      //Email
      if (newValue.email != null && newValue.email != '') {
        state.errors.email = null;
      }

      // Optional Messages
      if (state.extra_info_questions.length > 0) {
        for (let i = 0; i < 5; i++) {
          if (state.extra_info_questions[i] != [] && state.extra_info_questions[i].question != '') {
            if (newValue.extra_question_answers[i] != null && typeof newValue.extra_question_answers[i] !== 'undefined' && newValue.extra_question_answers[i] !== '') {
              state.errors.extra_question_answers[i] = null;
            }
          }
        }
      }
    }, {deep: true});


    /**
     * Validate User's answers
     * @returns {boolean}
     * @private
     */
    function __validateForm() {
      let valid = true;
      let validate_first_name = validateName(state.user.first_name, true);
      let validate_last_name = validateName(state.user.last_name, false);
      let valid_email = validateEmail(state.user.email);

      if (state.settings.first_name_required && validate_first_name != true) {
        state.errors.first_name = state.language_set[validate_first_name];
        valid = false;
      }
      if (state.settings.last_name_required && validate_last_name != true) {
        state.errors.last_name = state.language_set[validate_last_name];
        valid = false;
      }

      if (state.settings.email_required && valid_email != true) {
        state.errors.email = state.language_set[valid_email];
        valid = false;
      }

      if (state.settings.user_extra_info_mandatory && state.settings.user_extra_info_required) {
        let extra_valid = __validateDemographicQuestion();
        valid = valid && extra_valid;
      }

      return valid;
    }


    return {state, updateUser, updateUserOptionalAnswers, must_answer_extra_question};
  }
})
;
</script>

<style scoped lang="scss">
/*@import '@/theme/classmarker_theme';*/


.extra {
  &__form {
    & ion-item {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: $between-input-space;
      }
    }
  }
}

.extra-question-area {
  margin-top: $small-space;

  & ion-item {
    margin-top: $medium-space;
  }
}

.extra-question-item {
  max-width: 90%;
}

.extra-question-item :deep(.label .bbcode) {
  display: inline;
}

.input ion-label.label {
  max-width: fit-content;
}
</style>