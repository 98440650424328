<template>
    <div class="complete-modal-page">
        <div class="button-area">
            <ion-button id="test_confim_back_button" @click="dismiss()" :disabled="buttonDisabled">{{
                    state.language_set.link_go_back
                }}
            </ion-button>

            <ion-button id="test_confirm_finish" class="finish-button"  :disabled="buttonDisabled" style="margin-right:10px; margin-left:10px"
                        @click="completeTest()">
                {{ state.language_set.link_confirm_finish }}
            </ion-button>

        </div>
    </div>
</template>

<script>
import {IonButton, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import {defineComponent, onMounted, reactive, computed} from 'vue';
import store from "@/store";

export default defineComponent({
    name: 'CompleteTestModal',
    components: {IonContent, IonHeader, IonTitle, IonToolbar, IonPage, IonButton, IonIcon},
    props: {
        isButtonDisabled: {
            type: Boolean,
        }
    },
    setup(props, ctx) {
        const state = reactive({
            language_set: store.getters.languageSet,
        });

        function dismiss() {
            ctx.emit('dismiss-compete-test-modal');
        }

        function completeTest() {
            ctx.emit('complete-test');
        }

        const buttonDisabled = computed(()=>{
            return props.isButtonDisabled;
        })

        onMounted(() => {
            setTimeout(() => {
                let finish_button = document.querySelector('#test_confirm_finish');
                let inner_button = finish_button.shadowRoot.querySelector('button');
                inner_button.focus();
            }, 500)

        })
        return {state, dismiss, completeTest, buttonDisabled}
    }
});
</script>

<style lang="scss" scoped>
@import '../../theme/classmarker_theme';


.complete-modal-page {
    left: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
    position: relative;
    contain: inherit;
    display: table;
    height: 172px;
}

.button-area {
    min-width: 400px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 38px;
    margin-top: 0px;
    margin-bottom: 0px;
    display: table-cell !important;
    vertical-align: middle;
    padding: 0 10px;
    text-align: center;
}

.button-area .button {
    margin: 0 20px;
}

ion-header {
    padding: 24px 0px 24px 32px;
    border-bottom: 1px solid $color-gray-02;
    height: 68px;
    font-size: 20px;
}

.icon.close-button {
    position: absolute;
    top: 26px;
    right: 32px;
    cursor: pointer;
}

.modal-content {
    padding-left: 32px;
    padding-right: 27px;
    margin-top: 41px;
    margin-bottom: 52px;
}

#back-button {
    --background: $color-white;
    border: 1px solid $color-gray-02;
    border-radius: 4px;
    color: $text-color-primary;
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
    .sc-ion-modal-md-h {
        width: auto
    }
}

@media only screen and (max-width: 768px) {
    .button-area .button {
        margin: 10px;
    }
    .button-area {
        top: calc(50% - 19px);
    }
}
</style>
