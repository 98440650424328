import httpClient from "@/api/httpClient";
import responseHandler from "./responseHandler";
import userClient from "../api/userClient";

export const UserModule = {
    namespaced: true,

    state: {
        user: {
            first_name: null,
            last_name: null,
            login_email: null,
            email: null,
            extra_info_questions: [],
            access_code: null,
        }
    },
    mutations: {
        SET_USER_INFO(state, user) {
            if ('first_name' in user && user['first_name'] != null) {
                state.user['first_name'] = user['first_name'];
            }
            if ('last_name' in user && user['last_name'] != null) {
                state.user['last_name'] = user['last_name'];
            }
            // When login email updated, then update email too
            if ('login_email' in user && user['login_email'] != null) {
                state.user['login_email'] = user['login_email'];
            }
            if ('email' in user && user['email'] != null) {
                state.user['email'] = user['email'];
            }


            if ('extra_info_questions' in user && Array.isArray(user['extra_info_questions']) == true
                && user['extra_info_questions'].length > 0) {
                let extra_info = user['extra_info_questions']
                for (let i = 0; i < extra_info.length; i++) {
                    //set up user's answer
                    state.user.extra_info_questions[i] = extra_info[i]
                }
            }

            if ('extra_question_answers' in user && Array.isArray(user['extra_question_answers']) == true && user['extra_question_answers'].length > 0) {

                let extra_question_answers = JSON.parse(JSON.stringify(user['extra_question_answers']));

                for (let i = 0; i < extra_question_answers.length; i++) {
                    state.user.extra_info_questions[i].user_answer = extra_question_answers[i]
                }
            }
        },
        REMOVE_ACCESS_CODE(state){
            state.user['access_code'] = null;
        },
        SET_ACCESS_CODE(state, access_code){
            state.user['access_code'] = (typeof access_code === 'string' || access_code instanceof String) ? access_code.trim() : access_code;
        }
    },
    // Actions are functions that you call through your application that call mutations.
    actions: {
        /**
         * Get the initial of new test or the last state of ongoing test for the user
         * This should run once, when initialise app, so has to be synchronous (return promise)
         * @param commit
         * @param rootState
         * @returns {Promise<void>}
         */
        register({commit, rootState, dispatch}, user) {
            const promise = httpClient.post(rootState.Auth.token, '/user', user.user)
                .then((response) => {
                    commit('SET_USER_INFO', user.user);
                    responseHandler.setResponseData(commit, response);
                }).catch((error) => {
                    responseHandler.handleError(error);
                    throw error;
                });
            return promise
        },
        resume({commit, rootState, dispatch}, payload) {
            const promise = userClient.resume(rootState.Auth.token, payload.email, payload.password)
                .then((response) => {
                    responseHandler.setResponseData(commit, response);
                }).catch((error) => {
                    responseHandler.handleError(error);
                    throw error;
                });
            return promise;
        },
        update({commit, rootState, dispatch}, user) {
            const promise = httpClient.put(rootState.Auth.token, '/user', user.user)
                .then((response) => {
                    commit('SET_USER_INFO', user.user);
                    responseHandler.setResponseData(commit, response);
                }).catch((error) => {

                    responseHandler.handleError(error);
                    throw error;
                });
            return promise
        },

        setUserInfo({commit, dispatch}, payload) {
            commit('SET_USER_INFO', payload);
        },

        removeUserAccessCode({commit}){
            commit('REMOVE_ACCESS_CODE');
        },
        setAccessCode({commit}, access_code){
            commit('SET_ACCESS_CODE',access_code)
        },
        async checkEmailInUse({commit, rootState, dispatch}, payload) {
            try {
                const response = await userClient.checkEmailInUse(rootState.Auth.token, payload.email);
                let data = response.data;
                if (data.token != null) {
                    // set if token is rotated
                    commit('Auth/SET_TOKEN', data.token, {root: true});
                }

                return data.data;
            } catch (error) {
                responseHandler.handleError(error);
            }
        },

        async forgetPassword({commit, rootState}, payload){
            try{
                const response = await userClient.forgetPassword(rootState.Auth.token, payload.email);
                let data = response.data;
                if(data.token != null){
                    // set if token is rotated
                    commit('Auth/SET_TOKEN', data.token, {root: true});
                }
                return data.data;
            }catch( error ){
                responseHandler.handleError(error);
            }
        }
    },

    // Modulising stores - spreading stores into different files
    modules: {}
}