<template mode="ios">

  <ion-list :id="'matching-question-'+question.id" class="question-content-area" style="padding-top: 0;">

    <ion-item id="question_area" class="question-text">
      <div class="ion-no-margin question-text-label">
        <HtmlContent :content="question.question"></HtmlContent>
      </div>
    </ion-item>

    <ion-grid>
      <ion-row v-for="clue in question.options.clues" :key="clue.id">
        <ion-item class="match-clue-list-item">
          <ion-col size-sm="6" class="column clue-column">
            <ion-label class="ion-text-wrap ion-no-padding overflow-x-auto">
              <html-content :content="clue.content" :allow-magnifying-image="true"></html-content>
            </ion-label>
          </ion-col>


          <ion-col size-sm="6" class="column match-column ion-no-padding" :id="'match-column-'+clue.id">
            <ion-label class="ion-text-wrap ">
              <div v-if="clue.id in state.answered_content" class="match-content-area" v-bind:class="rightToLeft">
                <div class="overflow-x-auto">
                  <html-content :content="state.answered_content[clue.id]" :allow-magnifying-image="true"/>
                </div>
                <ion-row class="select-button-area">
                  <ion-button class="match-edit-button" color="light" v-on:click="showModal($event, clue.id, false)"
                              data-cy="matching-option-button">
                    {{ state.language_set.edit }}
                  </ion-button>
                </ion-row>
              </div>
              <div v-else>
                <ion-button class="match-button" v-bind:class="rightToLeft" @click="showModal($event, clue.id, false)"
                            v-on:matching_answer_update="updateAnswer" data-cy="matching-option-button">
                  <ion-icon class="icon circle-add"></ion-icon>
                  <ion-text class="select-text">
                    {{ state.language_set.matching_choose_answer }}
                  </ion-text>
                </ion-button>
              </div>
            </ion-label>
          </ion-col>
        </ion-item>
      </ion-row>
    </ion-grid>
  </ion-list>


  <div :id="'matching-modal-'+question.id" class="match-select-popup"
       :class="{ active : state.show_modal, rtl:reverse_text }"
       :style="modalStyleObject">
    <MatchingMediaModal :clue_id="state.clue_id_select" :matches="question.options.matches"
                        :height="measurements.modal_max_height" :arrow-position="state.arrow_position"
                        :arrow-from-top="state.arrow_from_top"
                        v-on:select-match="updateAnswer"></MatchingMediaModal>
  </div>


  <div id="modal-shadow" v-if="state.show_modal"
       :class="rightToLeft"
       :style="'height:'+(measurements.area_height - measurements.question_text_area_height -8)+'px;'+
            'top:'+measurements.question_text_area_height+'px'">
  </div>
</template>

<script>
import {IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow, IonText} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";
import MatchingMediaModal from "./MatchingMediaModal";
import store from "@/store";

export default defineComponent({
  name: 'MatchingMedia',
  components: {
    HtmlContent,
    IonList,
    IonItem,
    IonButton,
    IonText,
    IonLabel,
    IonPopover,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    MatchingMediaModal,
  },
  props: {
    question: Object,
    userAnswer: Object,
  },
  mounted: function () {
    /**
     * 1. Render the images if the user already answered after mounting the components.
     */

    for (const [clue_id, _] of Object.entries(this.state.user_answer)) {
      this.setMatchContent(clue_id);
    }

    /**
     * 2. Set initial modal height after rendering page.
     * When page is mounted, the contents height is not calculated properly.
     * To setup proper Modal height to avoid first load location issue,
     * We need to calculate the contents height prior to loading modal.
     * Wait for 500 ms to get the content modal size.
     */

    let me = this;
    setTimeout(function () {
      // 2. Set default value for modal & question area.
      let question_div_id = '#matching-question-' + me.$props.question.id;
      let question_div = document.querySelector(question_div_id);

      // get current modal height and question div height (These are changed by measurement are height)
      me.measurements.area_height = question_div.clientHeight;
      // set modal height once;
      me.measurements.modal_max_height = Math.floor(me.measurements.area_height * 0.8);

    }, 1000);


  },
  setup(props, ctx) {
    const state = reactive({
      user_answer: props.userAnswer ?? {},
      clue_id_select: props.question.options.matches[0].id,
      match_list: props.match_list,
      arrow_position: 0,
      show_modal: false,
      answered_content: {},
      language_set: store.getters.languageSet,
      arrow_from_top: true
    });
    /**
     * Set constant that text should render right to left
     */
    const reverse_text = store.getters.reverseText;

    /**
     * Update Answer when user select
     * This close the modal and update images.
     * @param object
     */
    function updateAnswer(object) {
      state.user_answer[object.clue_id] = object.match_id;
      setMatchContent(object.clue_id);
      ctx.emit('update:userAnswers', state.user_answer);
      state.show_modal = false;
    }

    /**
     * Set user selected contents for display contents in UI
     * @param clue_id
     * @private
     */

    function setMatchContent(clue_id) {
      let selected_match_id = state.user_answer[clue_id];
      props.question.options.matches.forEach((match) => {
        if (match.id == selected_match_id) {
          state.answered_content[clue_id] = match.content;
        }
      });
    }


    /**
     *  measurement variable for modal location calculation
     */
    const measurements = reactive({
      modal_height: 0,
      area_height: 0,
      modal_max_height: 0,
      question_text_area_height: 0
    });


    /**
     * Define where the match list modal should display
     * @type {ComputedRef<{top: string}>}
     */
    const modalStyleObject = computed(() => {
      {

        let object = {
          'top': state.modal_position + 'px',
        }
        return object;
      }
    });


    /**
     * Show modal - This function calculate where the modal positioned and the arrow location.
     * @param event
     * @param clue_id
     */
    function showModal(event, clue_id, selected) {

      let question_div_id = '#matching-question-' + props.question.id;
      let matching_modal_id = '#matching-modal-' + props.question.id;

      let question_div = document.querySelector(question_div_id);
      let modal_div = document.querySelector(matching_modal_id);

      /**
       *  Get Height Values from Dom elements.
       */
      measurements.area_height = question_div.clientHeight + 24; // margin bottom 24px;
      measurements.modal_max_height = Math.floor(window.innerHeight * 0.9);

      // if the question area is smaller than screen size, then ensure modal size is 90% of question area.
      if( measurements.area_height < window.innerHeight){
        measurements.modal_max_height = Math.floor(measurements.area_height * 0.9);
      }


      measurements.modal_height = modal_div.clientHeight;

      let question_text_area_div = question_div.querySelector('#question_area');
      measurements.question_text_area_height = question_text_area_div.offsetHeight;
      measurements.question_text_area_height += parseInt(window.getComputedStyle(question_text_area_div).getPropertyValue('margin-top'));


      // set modal height
      let actual_modal_height = measurements.modal_height;
      // css render height or max height which is smaller.
      if (measurements.modal_height > measurements.modal_max_height) {
        actual_modal_height = measurements.modal_max_height;
      }



      /**
       *  Find exact pixel location of selected Match button area
       */
      let match_columns = question_div.querySelectorAll('.match-column');
      let current_match_position_start_position = 0;

      let selected_column_id = 'match-column-' + clue_id;
      let match_area_height = 0;
      let found = false;
      let selected_item_index = 0;


      match_columns.forEach((match_column, index) => {
        let match_list_item_area = match_column.parentNode;
        let list_item_rect = match_list_item_area.getBoundingClientRect();

        if (match_column.id == selected_column_id) {
          found = true;
          selected_item_index = index;
        }

        if (found == false) {
          current_match_position_start_position = current_match_position_start_position + list_item_rect.height;
        }

        match_area_height += list_item_rect.height;
      })


      /**
       * Modal Location and arrow location calculation design details can be seen from the below link
       * ref: https://miro.com/app/board/uXjVMfmrjvc=/
       */

      // set Modal Location.
      state.modal_position = measurements.question_text_area_height + current_match_position_start_position;


      //set modal location
      let modal_bottom_position = state.modal_position + actual_modal_height;

      // set arrow position
      state.arrow_position = 20
      state.arrow_from_top = true;

      // if modal exceed bottom of the question area, then move the model up.
      if (modal_bottom_position > measurements.area_height) {
        let space_bottom = 20; // put space between modal and question area 20 px from bottom.
        let arrow_height = 42; //arrow height : 42px;

        state.modal_position = measurements.area_height - actual_modal_height - space_bottom;

        let distance_button_from_buttom = (match_area_height - current_match_position_start_position); // how much far from button
        state.arrow_position = distance_button_from_buttom - (space_bottom + arrow_height / 2);
        state.arrow_from_top = false;
      }

      /**
       * Setup State value for display modal
       */

      state.clue_id_select = clue_id;
      state.show_modal = true;
    }


    const rightToLeft = computed(() => {
      if (reverse_text) {
        return 'rtl';
      }
    });

    return {state, measurements, updateAnswer, showModal, modalStyleObject, setMatchContent, rightToLeft, reverse_text};
  },
});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

.question-text-label {
  margin-bottom: $medium-space;
}

.match-clue-list-item {
  width: 100%;
  min-height: 188px;

  padding-left: $medium-space;
  padding-right: $medium-space;


  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: $default-font-size;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin-top: 0px;

  /* Text primary */
  color: #454F59;

  & ion-item:first-of-type {
    margin-top: 0px;
  }
}

.column {
  padding-top: $medium-space;
  padding-bottom: $medium-space;
  height: 100%;
}

.clue-column {
  padding-left: 0px;
  padding-right: 10px;
}

.match-column {
  padding-left: 10px;
  padding-right: 0px;
}

.match-content-area {
  width: 100%;
  padding-left: 10%;
  position: relative;
  min-height: 188px;

  &.rtl {
    left: initial;
    right: 10%;
  }
}

.match-edit-button {
  margin-top: $medium-space;
}

.match-button {
  margin-top: $medium-space;
  margin-bottom: $medium-space;
  --background: #FFFFFF;
  border-radius: 5px;
  color: #919AA4;
  border: 2px dashed #E7E7E7;
  box-sizing: border-box;
  width: 90%;
  height: 188px;
  text-align: left;
  left: 10%;
  position: relative;

  &.rtl {
    right: 10%;
    left: initial;
  }
}

.icon.circle-add {
  width: 32px;
  height: 32px;
  background-size: 32px;
  position: absolute;
  top: calc(50% - 32px / 2 - 7px);
}

.select-text {
  top: calc(50% - 24px / 2 + 29px);
  position: absolute;
  font-size: $default-font-size;
  line-height: 22px;
  font-weight: normal;
  /* or 169% */

  text-align: center;
  letter-spacing: 0.02em;
}

.match-select-popup {
  position: absolute;
  z-index: 999;
  //right: 2%;
  right: 6%;
  width: 48%;
  background: transparent;
  border: none;
  left: -19999999px;


  @include responsive(size-small) {
    width: 75%;
  }

  &.rtl {
    left: initial;
    right: -19999999px;

    &.active {
      right: 50%;
    }
  }
}

.active {
  left: initial;

}

#modal-shadow {
  position: absolute;
  z-index: 9;
  width: 43%;
  top: 0px;
  //left: 54%;
  left: 49%;
  background-color: grey;
  opacity: 0.2;

  &.rtl {
    right: 54%;
    left: initial;
  }
}

:deep(.img) {
  max-width: 100%;
}

.clue-label {
  margin-top: 0px;
}
</style>