<template>
  <div class="bbcode" :class="classNames()" v-html="computed_content"
       :style="'text-align:' + textAlign" ref="content_ref"/>
</template>

<script>

import {computed, defineComponent, ref, inject} from "vue";
import bbCode from "../../utils/xbbcode";
import imageMagnifier from "../../utils/imageMagnifier";
import stringUtils from "../../utils/stringUtils";



export default defineComponent({
  name: 'HtmlContent',
  components: {},
  props: {
    content: String,
    allowMagnifyingImage: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    textAlign: {
      type: String,
      default: () => {
        return 'initial';
      }
    },
    /**
     * Set Display css as flex not block
     */
    flexDisplay: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    /**
     set css class names
     */
    cssClass: {
      type: String,
      default: () => {
        return '';
      }
    },
    // default: convert as bbcode
    // when it set to false:  don't do any of  html escape, bbcode conversion and linebreak conversion
    //                      ** only used when it is only used by developer in the UI it self.
    bbcode: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },

  setup(props, ctx) {
    const content_ref = ref([]);
    const {escapeHtml} = stringUtils();
    const freeAccount= inject('show_ads')

    function convertFromBBCode(content) {

      if (content == null) {
        content = '';
      }

      if (props.bbcode == true) {
        let escaped_content = escapeHtml(content);
        let html_content = bbCode(escaped_content, freeAccount);
        html_content = convertLinebreak(html_content);


        if (props.allowMagnifyingImage == true && html_content.includes("<img")) {
          __setupImageMagnifier();
        }
        return html_content;
      }

      else {
        return content;
      }
    }


    const computed_content = computed(() => {
          return convertFromBBCode(props.content);
        }
    );

    /**
     * Convert line break as <br/> tag
     * @param content
     * @returns {*}
     */
    function convertLinebreak(content) {

      //convert escaped <br/> as <br/>
      content = content.replace(/&lt;br\/&gt;/ig, "<br/>");
      content = content.replace(/\r\n/g, "\n");
      content = content.replace(/(\r|\n)/g, "<br/>");
      return content;
    }

    function __setupImageMagnifier() {

      setTimeout(() => {
        let image_list = content_ref.value.querySelectorAll('.imgw');
        for (let img of image_list) {
          __detect_image_onload(img);
        }
      }, 10)

      return;
    }

    function __detect_image_onload(img) {

      let interval_id = setInterval(() => {
        if ((img != undefined && img.complete && img.clientWidth !== 0)) {
          imageMagnifier.setup(img);
          clearInterval(interval_id);
        }
      }, 500)
    }

    const classNames = (() => {
      if (props.flexDisplay) {
        return "flex " + props.cssClass;
      } else {
        return props.cssClass;
      }
    });



    return {convertFromBBCode, content_ref, computed_content, classNames};
  },


});
</script>

<style>
.flex {
  display: flex !important;
}

/* All required styles for Cm BBcode elements */
.bbcode {
  display: block;
  position: relative;
  width: 100%;
}

.bbcode .sup {
  vertical-align: super;
  font-size: 80%;

}

.bbcode .sub {
  vertical-align: sub;
  font-size: 80%;

}

.bbcode .sqr {
  text-decoration: overline;
}

/* square root creation font-size: 105%; *//* Overridden in nonregV3.css due diff font size usage */
.bbcode table.bbcodeTable {
  border-collapse: collapse;
}

.bbcode table.bbcodeTable, .bbcode table.bbcodeTable th, .bbcode table.bbcodeTable td {
  border: 1px solid black;
  padding: 4px;
}

.bbcode .small {
  font-size: 80%;
}

.bbcode .normal {
  font-size: 100%;
}

.bbcode .large {
  font-size: 120%;
}

.bbcode .bbcodeSize1 {
  font-size: .9em;
}

.bbcode .bbcodeSize2 {
  font-size: 1em;
}

.bbcode .bbcodeSize3 {
  font-size: 1.3em;
}

.bbcode .bbcodeSize4 {
  font-size: 1.9em;
}

.bbcode .bbcodeSize5 {
  font-size: 2.2em;
}

.bbcode .bbcodeSize6 {
  font-size: 2.47em; /* IMPORTANT: same as original certificate "Main Title" size - for backward compatibility to Version 1 certs */
}

.bbcode .bbcodeSize7 {
  font-size: 2.7em;
}

/*Arial Black,Comic Sans MS,Courier New,Droid Arabic Kufi,Georgia,Impact,Sans-serif,Serif,Times New Roman,Trebuchet MS,Verdana*/
.bbcode .bbcodeFontArial {
  font-family: Arial;
}

.bbcode .bbcodeFontArialBlack {
  font-family: "Arial Black";
}

.bbcode .bbcodeFontComicSansMS {
  font-family: "Comic Sans MS";
}

.bbcode .bbcodeFontCourier {
  font-family: Courier;
}

.bbcode .bbcodeFontCourierNew {
  font-family: "Courier New";
}

.bbcode .bbcodeFontGeorgia {
  font-family: Georgia;
}

.bbcode .bbcodeFontImpact {
  font-family: Impact;
}

.bbcode .bbcodeFontHelvetica {
  font-family: Helvetica;
}

.bbcode .bbcodeFontOpenSans {
  font-family: 'Open Sans', sans-serif;
}

.bbcode .bbcodeFontSansSerif {
  font-family: Sans-serif;
}

.bbcode .bbcodeFontSerif {
  font-family: Serif;
}

.bbcode .bbcodeFontTimesNewRoman {
  font-family: "Times New Roman";
}

.bbcode .bbcodeFontTrebuchetMS {
  font-family: "Trebuchet MS";
}

.bbcode .bbcodeFontVerdana {
  font-family: Verdana;
}

.bbcode .bbcodeFontNotoSansJapanese {
  font-family: "Noto Sans JP";
}

.bbcode .bbcodeFontNotoSansSC {
  font-family: "Noto Sans SC";
}

.bbcode .bbcodeFontNotoSansTC {
  font-family: "Noto Sans TC";
}

.bbcode .bbcodeFontNotoSansKR {
  font-family: "Noto Sans KR";
}

.bbcode .bbcodeFontDroidArabicKufi {
  font-family: "Droid Arabic Kufi";
}

.bbcode ol {
  list-style: decimal;
  margin: 0 35px;
}

.bbcode ul {
  list-style: disc;
  margin: 0 35px;
}

p.bbcode {
  display: block;
}

.bbcode span.xbbcode-code {
  display: inline-block;
  margin: 10px 0;
  padding: 10px;
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 95%;
  line-height: 140%;
  background: #f7f7f7;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.bbcode video, .v_clue audio, .v_result audio, #display_q_links audio, #display_q_links video {
  width: 95%;
}

/* Start remove download buttons on chrome for video and audio html5 players */
video::-internal-media-controls-download-button, audio::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure, audio::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel, audio::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}

/* End remove download buttons on chrome for video and audio html5 players */
</style>
