<template>
  <form @submit="resume($event)">
    <ion-list class="authentication__content--list" lines="none" aria-label="hidden">
      <ion-list-header class="authentication__content--titles">
        <ion-label class="ion-text-wrap authentication__content--title">
          {{ state.language_set.ex_resume_test }}
        </ion-label>

      </ion-list-header>

      <ion-item>
        <div class="input">
          <ion-label class="ion-text-wrap label">{{ state.language_set.ex_login_email_address }}</ion-label>
          <div id="email-area">
            <text id="user-email-text">{{ state.email }}</text>
            <ion-icon class="icon circular-tick"></ion-icon>
          </div>
        </div>
      </ion-item>

      <ion-item>
        <div class="input">
          <ion-label class="ion-text-wrap label" :class="{'error':state.errors.password}">
            {{ state.language_set.ex_password }}
          </ion-label>
          <ion-input id="password" class="small" type="password" :placeholder=" state.language_set.ex_password "
                     :clearOnEdit="false"
                     :value="state.password" maxlength="72"
                     @ionChange="resetForm()"
                     v-bind:class="{'error':(state.errors.password!=null)}">
          </ion-input>
          <div class="form_error">{{ state.errors.password }}</div>
        </div>
      </ion-item>

    </ion-list>

    <div class="authentication__footer">
      <div v-if="state.errors.api.message" class="authentication__footer__banner">
        <banner :message="state.errors.api.message" :banner-type="state.errors.api.type"></banner>
      </div>

      <div>
        <ion-button class="button" fill="solid" type="submit" :disabled="state.disable_button" data-cy="continue-btn">
          {{ state.language_set.ex_resume_test }}
        </ion-button>

        <a role="button" v-on:click="goBack()" class="go-back-link" data-cy="previous-btn" tabindex="0">
          {{ state.language_set.link_go_back }}
        </a>


      </div>
      <div class="authentication__footer--link">
        <a role="button" v-on:click="setOpen(true)">
          {{ state.language_set.ex_link_forgot_password }}
        </a>
      </div>
    </div>
  </form>

  <ion-modal
      :is-open="state.is_open_ref"
      :css-class="$setComponentOrientation('reset-password-modal')"
      @didDismiss="setOpen(false)">
    <ResetPassword @dismiss-modal="dismissModal" @reset_email_sent="showEmailSentMessage" @no-internet='noInternetError'
                   :email="state.email"></ResetPassword>
  </ion-modal>
</template>

<script>
import {IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonModal} from '@ionic/vue';
import {reactive, ref} from "vue";
import ResetPassword from "./ResetPassword";
import store from "@/store";
import RouteHandler from "../../router/routeHandler";
import {useRouter} from "vue-router";
import validateUtil from "../../utils/validateUtil";
import Banner from "../html/Banner";
import htmlSetupUtil from "../../utils/htmlSetupUtil";
import constant from "../../constant";


export default {
  name: "ResumePanel",
  components: {
    IonInput,
    IonLabel,
    IonItem,
    IonList,
    IonButton,
    IonListHeader,
    ResetPassword,
    IonModal,
    Banner,
    IonIcon
  },
  emits: ['start-panel'],
  setup(props, ctx) {
    const state = reactive({
      modal_open: true,
      is_open_ref: ref(false),
      email: store.state.User.user['login_email'],
      password: null,
      language_set: store.getters.languageSet,
      disable_button: false,
      errors: {
        "password": null,
        "api": {
          "message": null,
          "type": null
        },
      }
    });
    const route_handler = new RouteHandler(useRouter());
    const {validateEmail, validatePassword} = new validateUtil()

    function setOpen(open) {
      state.is_open_ref = open;
    }

    function dismissModal() {
      setOpen(false);
    }

    function checkForm() {


      let valid_password = validatePassword(state.password);


      if (valid_password !== true) {
        state.errors.password = state.language_set[valid_password];
      }

      if (state.errors.password != null) {
        return false;
      }
      return true;
    }

    function resume(event) {
      event.preventDefault();

      // V-model with ion-input displays password. This code is to avoid to expose password in the form
      let password = document.querySelector('#password');
      state.password = password.value;


      state.disable_button = true
      if (!checkForm()) {
        state.disable_button = false;
        return false;
      }
      store.dispatch('User/resume', {
        email: state.email,
        password: state.password
      }).then(() => {
        route_handler.route();
        state.disable_button = false;
      }).catch((error) => {

        if (store.state.Error.error.error_code == constant.ERROR_NO_INTERNET) {
          store.dispatch('Error/openModal');
        } else if ('field' in error.response.data && error.response.data.field == 'password') {
          state.errors.password = state.language_set[error.response.data.error_code];
        } else {
          let error_code = error.response.data.error_code;
          let error_level = error.response.data.error_level;
          let error_message = state.language_set[error_code] ?? error.response.data.error_message;

          state.errors.api.message = error_message;
          state.errors.api.type = error_level;

        }
        state.disable_button = false;
      })

    }

    function resetForm() {
      if (state.errors.password != null || state.errors.api.message != null) {
        state.errors.password = null;
        state.errors.api.message = null;
        state.errors.api.type = null;
      }
    }

    function goBack() {
      ctx.emit('start-panel', true);
    }


    function showEmailSentMessage() {

      state.errors.api.message = '[b]' + state.language_set['reset_password'] + '[/b]<br/><br/>'
          + state.language_set['ex_password_sent_text'] + '<br/>'
          + state.language_set['reset_password_token_expire'];
      state.errors.api.type = 'info';
    }


    const {getModalClassName} = htmlSetupUtil()

    function noInternetError() {
      // close forget password modal
      dismissModal();
      // open global error modal
      store.dispatch('Error/openModal');
    }


    return {
      state,
      setOpen,
      dismissModal,
      resume,
      resetForm,
      goBack,
      showEmailSentMessage,
      noInternetError
    };
  },
};
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

.label {
  opacity: 10 !important;
}

#email-area {
  background: $color-gray-01;
  padding-top: 7px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  width: 250px;
  min-height: 35px;

  & text {
    max-width: 210px;
  }

  .icon.circular-tick {
    position: absolute;
    right: -18px;
    top: 10px;
  }
}

.authentication {


  &__content {
    margin: 0 $large-space;

    &--titles {
      margin-bottom: $small-space;
      padding: 0;
      display: flex;
      flex-direction: column;
      color: $color-gray-primary-2;
    }

    &--title {
      font-family: $default-font-family;
      font-size: $medium-font-size;
      font-weight: bold;
      line-height: 22px;
      width: 100%;
    }

    &--subtitle {
      font-family: $default-font-family;
      font-size: $default-font-size;
      line-height: 1.4;

      width: 100%;
    }

    &--list {
      padding: 0;

      & ion-item {
        &:not(:last-child) {
          margin-bottom: $small-space;
        }
      }
    }
  }


  &__footer {
    display: block;
    flex-direction: row;
    margin-top: $medium-space;
    align-items: center;


    &__banner {
      margin-bottom: $large-space;
    }

    &--link {
      margin-top: $between-space;
      font-size: $default-font-size-link;
      font-family: $secondary-font-family;

      &:hover {
        cursor: pointer;
      }
    }
  }


}

.go-back-link {
  position: relative;
  top: 5px;
  margin-left: $medium-space;

  &:hover {
    cursor: pointer;
  }
}


</style>