<template>
  <div class="template-title" v-if="displayLogo && (displayTitle || displayPrice)">
    <ion-grid class="test-title-area" :class="{'custom-title': hasCustomHeader}">
      <ion-row>
        <ion-col class="ion-no-padding disable-printer" v-if="displayAds">
          <div>
            <google-ads :google-ad-slot="googleAdSlot"></google-ads>
          </div>
        </ion-col>

        <ion-col id="test-title" class="ion-no-padding" :size="titleSize">
          <h1 v-if="displayTitle && state.theme.test_title">
            {{ state.theme.test_title }}
          </h1>

        </ion-col>
        <ion-col class="price-area ion-text-end ion-no-padding" size="4" v-if="displayPrice && state.price !== null">
          <div class="price">{{ currencySymbol }}{{ state.price.price }} <sup
              class="very-small-font-size">{{ state.price.currency }}</sup></div>
          <div class="very-small-font-size">{{ state.price.tax_note }}</div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <div class="template-title" style="padding-top:15px; padding-bottom:15px"
       v-if="state.theme.display_cm_free_trail_banner">
    <Banner
        message="This Test is being taken under a <a href='https://www.classmarker.com/' target='_blank'>ClassMarker.com</a> 30 Day Free Trial Account."
        banner-type="info" :display-icon="true" :bbcode="false"></Banner>
  </div>
</template>

<script>
import {IonCol, IonGrid, IonHeader, IonIcon, IonImg, IonRow, IonToolbar} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import store from "@/store";
import HtmlContent from "./HtmlContent";
import Banner from "./Banner";
import GoogleAds from "./GoogleAds";
import stringUtils from "../../utils/stringUtils";


export default defineComponent({
  name: 'TestTitle',
  components: {
    HtmlContent,
    IonHeader,
    IonImg,
    IonToolbar,
    IonGrid,
    IonCol,
    IonRow,
    Banner,
    GoogleAds,
    IonIcon
  },
  props: {
    displayPrice: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    displayLogo: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    displayTitle: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    displayAds: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    googleAdSlot: {
      required: false,
    }
  },
  setup(props, ctx) {
    const state = reactive({
      theme: store.getters.theme,
      price: store.state.Test.data.price,
    });

    const {convertCurrencySymbol} = stringUtils();
    const currencySymbol = computed(() => {
      return convertCurrencySymbol(state.price.currency);
    })

    const hasCustomHeader = computed(() => {
      if ((state.theme.header_img != null && state.theme.header_img != '') ||
          (state.theme.display_title != null && state.theme.display_title != '')
      ) {
        return true;
      }
      return false;
    });

    const titleSize = computed(() => {
      if (props.displayPrice && state.price !== null) {
        return 8;
      } else {
        return 12;
      }
    })

    return {state, hasCustomHeader, currencySymbol, titleSize};
  },


});
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

ion-toolbar {
  width: fit-content;
}

.price {
  color: var(--ion-color-primary);
  font-size: $large-font-size;
  font-weight: bold;
}


.template-title {
  background-color: var(--color-white);
  max-width: var(--width-max-template);
  margin: auto;
  padding-bottom: calc(32px / 2);

  font-size: $default-font-size;
  letter-spacing: $letter-spacing;
  font-family: $default-font-family;
  padding-bottom: 0px;
}

.test-title-area {
  //padding-bottom: 24px;
  padding-bottom: 12px;
}

.custom-title {
  padding-top: 24px;
}

.theme_title {
  font-size: 1.5em;
  padding: 10px;
}


</style>
