import {createApp} from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router';
import store from './store'
import {IonicVue} from '@ionic/vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/icon.scss';

/* Global Components */
import GlobalErrorModal from "./components/html/GlobalErrorModal";
import globalFunctions from "./utils/globalFunctions";
import global_methods from "./utils/globalFunctions";
import mitt from "mitt";
import {createGtm, useGtm} from "@gtm-support/vue-gtm";
// import CookieConsentVue from "@/plugins/cookie-consent/CookieConsentVue";
// import {cookieConsentConfig} from "@/plugins/cookie-consent/config";


const emitter = mitt();
const app = createApp(App)
    .use(IonicVue)
    .use(Vuex)
    .use(store)
    .use(router)
    .use(globalFunctions)
    // .use(CookieConsentVue, {cookieConsentConfig})
    .component('GlobalErrorModal', GlobalErrorModal);
app.config.globalProperties.$globalEmitter = emitter;
app.config.globalProperties.$popupUnLoadEvent = event => {
    global_methods.popupUnloadEvent(event)
};
app.config.globalProperties.$setComponentOrientation = name => {
    return global_methods.setComponentOrientation(name);
};
app.config.globalProperties.$getCorrectAriaText = icon_class => {
    return global_methods.getCorrectAriaText(icon_class);
}
app.config.globalProperties.$multipleChoiceLabelClicked = (event, option_id) => {
    return global_methods.multipleChoiceLabelClicked(event, option_id);
}
app.config.globalProperties.$namesInReverseOrder = lang_code => {
    return global_methods.namesInReverseOrder(lang_code);
}

app.use(
    createGtm({
        id: 'GTM-5DGG58D', // GTM single container ID
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        //nonce: '2726c7f26c', // Will add `nonce` to the script tag, for use with Consent Manager System
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        //ignoredViews: ['homepage'], // Don't trigger events for specified router names
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        /*
        queryParams: {   //For use in Enviroments (see GTM Enviroments)
            // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
            gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
            gtm_preview: 'env-4',
            gtm_cookies_win: 'x',
        },
        */
    }),
);

//Send all VueJS errors to GA/GTM
app.config.errorHandler = (err, instance, info) => {
    const gtm = useGtm();
    gtm.trackEvent({
        event: 'vuejs_error',
        error_message: err.message,
        error_stack: err.stack,
        error_info: info,
        error_url: window.location.href,
    });
}


router.isReady().then(() => {
    app.mount('#app');
});







