<template>
  <ion-card class="result-card">
    <ion-card-header class="result-card__header ion-no-padding">
      <ion-card-title>
        <div ref="test_title_ref" data-cy="result-card-title">{{ title }}</div>
        <div class="result-card__header__user" v-if="fullName !== ''">
          <ion-icon class="icon user"></ion-icon>
          <div class="result-card__header__user__name" data-cy="result-card-name"><span ref="name_ref" v-html="fullName"/>
            <span class='result-card__header__user__name__print-text disable-printer' v-if="settings.print_allowed"
            ><ins @click="print()">{{ state.language_set.rh_print_header }} </ins></span>

          </div>

        </div>
      </ion-card-title>

    </ion-card-header>


    <ion-card-content class="result-card__content ion-no-padding">

      <ion-grid>
        <ion-row class="require_grading_banner" v-if="requireGrading">
          <banner
              :message="'[b]'+state.language_set.note+'[/b]: ' +state.language_set.test_unmarked_questions_exists_user"
              banner-type="warning">
          </banner>
        </ion-row>
        <ion-row size-xs="12" size-sm="6">
          <ion-col>
            <ion-row v-if="displayScore">
              <ion-col class='no-padding-left no-padding-right' size-xs="6" size-sm="5"><strong>{{
                  state.language_set.points
                }}:</strong></ion-col>
              <ion-col size-xs="6" size-sm="7">
            <span v-bind:class="{'bg-yellow': requireGrading}" ref="score_ref" data-cy="result-card-score">{{
                formatNumber(pointsScored)
              }} / {{ formatNumber(pointsAvailable) }}</span>
              </ion-col>
            </ion-row>

            <ion-row v-if="pointsPercentage!==null">
              <ion-col class='no-padding-left no-padding-right' size-xs="6" size-sm="5">
                <strong>{{ state.language_set.quiz_percentage }}:</strong>
              </ion-col>
              <ion-col size-xs="6" size-sm="7"><span
                  v-bind:class="{'bg-yellow': requireGrading}" ref="percentage_ref"
                  data-cy="result-card-percentage">{{ pointsPercentage }}%</span>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class='no-padding-left no-padding-right' size-xs="6" size-sm="5">
                <strong>{{ state.language_set.quiz_duration }}:</strong>
              </ion-col>
              <ion-col size-xs="6" size-sm="7"><span ref="duration_ref" data-cy="result-card-duration">{{
                  duration
                }}</span></ion-col>
            </ion-row>

            <ion-row>
              <ion-col class='no-padding-left no-padding-right' size-xs="6" size-sm="5"><strong>{{
                  state.language_set.quiz_date_started
                }}:</strong>
              </ion-col>
              <ion-col size-xs="6" size-sm="7"><span ref="start_date_ref"
                                                     data-cy="result-card-date-started">{{ dateStarted }}</span>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class='no-padding-left no-padding-right' size-xs="6" size-sm="5"><strong>{{
                  state.language_set.quiz_date_finished
                }}:</strong>
              </ion-col>
              <ion-col size-xs="6" size-sm="7"><span ref="end_date_ref"
                                                     data-cy="result-card-date-finished">{{ dateFinished }}</span>
              </ion-col>
            </ion-row>
          </ion-col>


          <ion-col size-xs="12" size-sm="6" class="result-card__content__buttons"
                   v-if="(certificateUrl !==null && certificateUrl != '') || receiptUrl !==null">

            <div class="button-col" v-if="certificateUrl !==null && certificateUrl != ''"
                 @click="downloadCertificate()">
              <ion-row class="button-icon">
                <certificate-button :disabled="certificate_download_ref"
                ></certificate-button>
              </ion-row>
              <ion-row>
                <span class="button-icon-text">{{ state.language_set.download_certificate }}</span>
              </ion-row>
            </div>

            <div class="button-col" v-if="receiptUrl">
              <ion-row class="button-icon">
                <receipt-button :disabled="receipt_download_ref"  @click="downloadReceipt()"></receipt-button>
              </ion-row>
              <ion-row>
                <span class="button-icon-text">{{ state.language_set.link_download_receipt }}</span>
              </ion-row>
            </div>

          </ion-col>
          <ion-col size-xs="12" size-sm="6" v-else>

          </ion-col>

        </ion-row>

        <Banner class="result-card__content__certificate__warning" v-if="certificate_error_ref"
                :message="certificate_message"
                bannerType="warning" style="margin-top:20px;"/>

        <FeedbackBanner class="result-card__content__feedback" v-if="feedback" :message="feedback"
                        :bannerType="passed ? 'success': 'warning'"/>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow
} from '@ionic/vue';

import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import FeedbackBanner from "../test/feedbacks/FeedbackBanner";
import store from "@/store";
import stringUtils from "../../utils/stringUtils";
import testClient from "../../api/testClient";
import Banner from '../html/Banner';
import PrinterButton from "../icons/PrinterButton";
import CertificateButton from "../icons/CertificateButton";
import ReceiptButton from "../icons/ReceiptButton";
import responseHandler from "../../store/responseHandler";
import authHandler from "../../router/authHandler";
import constant from "../../constant";
import he from "he";

export default defineComponent({
  name: 'TestResultCard',
  components: {
    IonCard,
    IonCardHeader,
    IonButton,
    IonIcon,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonCol,
    IonRow,
    IonGrid,
    Banner,
    FeedbackBanner,
    PrinterButton,
    CertificateButton,
    ReceiptButton
  },
  props: {
    title: String,
    pointsScored: [Number, String, null],
    pointsAvailable: [Number, String, null],
    pointsPercentage: [Number, String, null],
    duration: [Number, String],
    passed: Boolean,
    dateStarted: [Number, String],
    dateFinished: [Number, String],
    feedback: String,
    certificateUrl: String,
    displayScore: Boolean,
    settings: Object,
    disablePrintButton: Boolean,
    requireGrading: Boolean,
    receiptUrl: [String, null]
  },
  setup(props, ctx) {

    const state = reactive({
      language_set: store.getters.languageSet,
      locale_code: store.getters.theme
    });


    const {formatNumber, escapeHtml} = new stringUtils();
    const certificate_error_ref = ref(false);
    const certificate_download_ref = ref(false);
    const receipt_download_ref = ref(false);

    const certificate_message = "[b]Certificate Server Busy.[/b]\n"
        + "Please wait for 30 seconds then you can try to download your Certificate again.";



    const fullName = computed(function () {
      return store.getters.userFullName;
    });


    const downloadReceipt = async function () {
      receipt_download_ref.value = true;
      window.location.assign(props.receiptUrl);
      setTimeout(() => {
        receipt_download_ref.value = false;
      }, 10000)
    }

    const downloadCertificate = async function () {

      if(certificate_download_ref.value){
        return ;
      }
      let certificate_url = props.certificateUrl;
      certificate_download_ref.value = true;
      let response;
      try {
        response = await testClient.checkPDFDownload(store.state.Auth.token);
      } catch (e) {
        responseHandler.handleNoInternetError(e, true);
      }

      if (response == 'ok') {
        window.location.assign(certificate_url);
        setTimeout(() => {
          certificate_download_ref.value = false;
        }, 10000);
      } else {
        certificate_error_ref.value = true;
        certificate_download_ref.value = false;

        // display error message for 3 seconds
        setTimeout(() => {
          certificate_error_ref.value = false;
        }, 10000);
      }
    }

    function print() {
      ctx.emit('print');
    }


    /**
     * Cheating detection variables
     */
    let cheat_timer_id;
    const score_ref = ref();
    const percentage_ref = ref();
    const duration_ref = ref();
    const start_date_ref = ref();
    const end_date_ref = ref();
    const name_ref = ref();
    const test_title_ref = ref();

    onMounted(() => {

      let cheated_values = {};
      let original_value = null;

      cheat_timer_id = setInterval(() => {

        if (original_value === null) {
          original_value = captureOriginalValues();
        }

        // detect cheat on scores
        detectCheatedValues(original_value, cheated_values);

        if (Object.keys(cheated_values).length > 0) {

          // clear interval
          clearInterval(cheat_timer_id);


          //delete auth token from cookie
          let quiz_id = store.getters.quizId;
          authHandler.deleteToken(quiz_id);

          // report cheating to server
          store.dispatch('Test/detectCheating', {
            cheat_type: constant.CHEAT_TYPE_TAMPER_RESULT,
            cheat_details: cheated_values
          });
        }


      }, 3000);

      function captureOriginalValues() {
        let original_value = {};
        // detect cheat on scores

        if (props.displayScore) {
          original_value['score'] = __captureOriginalValue(score_ref);
        }

        // detect cheat on percent
        if (props.pointsPercentage !== null) {

          original_value['percentage'] = __captureOriginalValue(percentage_ref);
        }

        // detect cheat on duration
        original_value['duration'] = __captureOriginalValue(duration_ref);
        original_value['test_title'] = __captureOriginalValue(test_title_ref);


        //detect cheat on user's name
        let name = __getUserFullName();
        if (name != '') {
          original_value['user_name'] = __captureOriginalValue(name_ref);
        }
        original_value['start_date'] = __captureOriginalValue(start_date_ref);
        original_value['finish_date'] = __captureOriginalValue(end_date_ref);
        return original_value;
      }

      function __getUserFullName() {
        return store.getters.userFullName
      }


      function detectCheatedValues(original_value, cheated_values) {
        if (props.displayScore) {
          let original_score_text = original_value['score'];
          let score_changed_text = __detectTextChange(score_ref, original_score_text);
          if (score_changed_text) {
            cheated_values.score = original_score_text;
            cheated_values.changed_score = score_changed_text;
          }
        }

        // detect cheat on percent
        if (props.pointsPercentage !== null) {
          let original_percent_text = original_value['percentage'];
          let percent_changed_text = __detectTextChange(percentage_ref, original_percent_text);
          if (percent_changed_text) {
            cheated_values.percent = original_percent_text;
            cheated_values.changed_percent = percent_changed_text;
          }
        }

        // detect cheat on duration
        let duration_changed_text = __detectTextChange(duration_ref, original_value['duration']);
        if (duration_changed_text) {
          cheated_values.duration = original_value['duration'];
          cheated_values.changed_duration = duration_changed_text;
        }

        // detect cheat on test title
        let title_changed_text = __detectTextChange(test_title_ref, original_value['test_title'], true);
        if (title_changed_text) {
          cheated_values.title = he.decode(original_value['test_title']);
          cheated_values.changed_title = title_changed_text;
        }



        //detect cheat on user's name
        let name = __getUserFullName();

        if (name != '') {
          let user_name_changed_text = __detectTextChange(name_ref, original_value['user_name'], true);
          if (user_name_changed_text) {
            cheated_values.user_name = he.decode(original_value['user_name']);
            cheated_values.changed_user_name = user_name_changed_text;
          }
        }

        let date_started_changed_text = __detectTextChange(start_date_ref, original_value['start_date']);
        if (date_started_changed_text) {
          cheated_values.date_started = original_value['start_date'];
          cheated_values.changed_date_started = date_started_changed_text;
        }

        let date_finished_changed_text = __detectTextChange(end_date_ref, original_value['finish_date']);
        if (date_finished_changed_text) {
          cheated_values.date_finished = original_value['finish_date'];
          cheated_values.changed_date_finished = date_finished_changed_text;
        }
      }

      /**
       * Detected text of target element is changed
       * @param element_ref
       * @param original_value
       * @returns {boolean}
       * @private
       */
      function __captureOriginalValue(element_ref) {
        let element = element_ref.value;
        let element_text = element.innerHTML;
        return element_text;
      }

      function __detectTextChange(element_ref, original_value, escape_special_char = false) {
        let element = element_ref.value;
        let element_text = element.innerHTML;

        if (escape_special_char) {
          element_text = he.decode(element_text);
          original_value = he.decode(original_value);
        }

        if (original_value != element_text) {
          return element_text;
        }
        return null;
      }


    });

    return {
      state,
      fullName,
      formatNumber,
      print,
      downloadCertificate,
      certificate_error_ref,
      certificate_message,
      certificate_download_ref,
      receipt_download_ref,
      downloadReceipt,
      score_ref,
      percentage_ref,
      duration_ref,
      start_date_ref,
      end_date_ref,
      name_ref,
      test_title_ref
    };
  },


});
</script>

<style scoped lang="scss">
@import '../../theme/classmarker_theme';

.result-card {
  margin: 0px;
  padding: 34px 24px 34px 31px;

  &__header {
    background-color: $color-white;
    min-height: 72px;
    display: grid;
    grid-template-columns: 1fr max-content;
    font-family: $default-font-family;
    padding-bottom: 20px;

    &__user {
      width: 100%;
      text-align: left;
      margin-top: 11px;
      margin-bottom: 6px;
      display: block;
      font-size: $default-font-size;

      .icon.user {
        margin-left: 2px;
        width: 22px;
        height: 22px;
        background-size: 22px;
        display: inline-block;
        vertical-align: middle;

      }

      &__name {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        font-weight: initial !important;
        color: $color-gray-04;

        &__print-text {
          margin-left: 16px;
          border-left: 2px solid $color-gray-03;
          padding-left: 16px;
          //text-decoration: underline;

          & > ins {
            cursor: pointer;
            letter-spacing: 0.02em;
            line-height: 22px;
            color: $color-gray-04;
          }
        }
      }

      @include responsive(size-small) {
        font-size: 14px;
        height: 22px;
        display: inline-block;

        right: 10px;
      }
    }

    & ion-card-title {
      color: $text-color-primary;
      font-size: 24px !important;
      font-weight: bold !important;
    }
  }

  &__content {
    //padding-top: 24px;
    //border-top: 1px solid $color-gray-02;

    .require_grading_banner {
      margin-top: 12px;
      margin-bottom: 24px;
    }

    & ion-label {
      font-weight: bold !important;
      width: 30%;
    }

    span.bg-yellow {
      padding: 4px 11px 4px 11px;
      border-radius: 5px;
      background: #FFFAE1;
      height: 22px;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid $color-gray-02;

      @include responsive(size-small) {
        border-left: none;
        padding-top: 32px;
      }
    ;

      .button-col {
        width: 112px;
        margin-left: 24px;
        margin-right: 24px;
        cursor: pointer;

        @media only screen and (max-width: 447px) {
          display: block;
        };
        @media only screen and (min-width: 448px) {
          display: initial;
        };

        & ion-row {
          padding: 0px;
        }

        .button-icon :deep(.icon-button-lg) {
          position: relative;
          margin: auto;
        }
      }

      .button-icon {
        display: flex;
        margin: auto;
        position: relative;
      }

      .button-icon-text {
        display: flex;
        width: 70px; /* 40px: for korean translation if it is reuqired*/
        position: relative;
        margin: 10px auto;
        text-align: center;
        color: $color-gray-secondary;
        font-size: 11px;
        line-height: 14px;
        word-wrap: break-word;

      }
    }

    &__feedback {
      margin-top: 28px;
    }
  }
}


</style>